// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import {
    IBackupSettings,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import { BackupsTableContainer } from 'common/components/ServerTabs/Styles';
import BackupsTable,
{ IBackupsTableColumns } from 'common/modules/backup/containers/BackupsTable';
import BackupCard from 'common/containers/BackupCard/BackupCard';
import { bindedActionCreators } from 'common/redux/types';
import { Translate } from '@plesk/ui-library';
import { ImageFormat } from 'common/api/resources/StorageType';
import { CancelTokenSource } from 'axios';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';

export interface IBackupsTabProps {
    item: IVmResponse;
    isBackupSettingsUpdating: boolean;
    isBackupLoading: boolean;
    shouldHideStatusDetailsButton?: boolean;
    computeResourceVmActions: bindedActionCreators<typeof  computeResourceVmActions>;
    unsetBackups: () => void;
    confirmEnableBackupsOnQcow?: boolean;
    columns: IBackupsTableColumns;
    shouldShowBackupsWhenDisabled: boolean;
    isBackupEnabledGlobally: boolean;
}

export const BackupsTab: React.FC<IBackupsTabProps> = ({
    item,
    isBackupSettingsUpdating,
    isBackupLoading,
    shouldHideStatusDetailsButton,
    computeResourceVmActions: {
        updateBackupSettings,
        getBackups,
    },
    unsetBackups: unsetVmBackups,
    confirmEnableBackupsOnQcow,
    columns,
    shouldShowBackupsWhenDisabled,
    isBackupEnabledGlobally,
}) => {
    const shouldShowBackups = shouldShowBackupsWhenDisabled || item.backup_settings.enabled;

    React.useEffect(() => {
        if (shouldShowBackups) {
            getBackups(item.id);
        }

        return () => unsetVmBackups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setBackupSettings = async (backupSettings: IBackupSettings) => {
        await updateBackupSettings(item.id, backupSettings);

        const switchedOn = !item.backup_settings.enabled && backupSettings.enabled;
        const switchedOff = item.backup_settings.enabled && !backupSettings.enabled;

        switch (true) {
        // User enables backups => we should reload.
        case switchedOn:
            getBackups(item.id);
            break;

        // User disables backups and:
        // should not show them or backup settings are disabled globally =>
        // we should remove all backups from the store.
        case switchedOff && (!shouldShowBackupsWhenDisabled || !isBackupEnabledGlobally):
            unsetVmBackups();
            break;
        }
    };

    // We should warn user that backup restore for VM's with qcow2 image make lose
    // snapshots.
    let confirmTextOnEnable;
    if (confirmEnableBackupsOnQcow && item.plan.image_format === ImageFormat.QCOW2) {
        confirmTextOnEnable = <Translate content="backupCard.enableConfirm.qcow2.text" />;
    }

    const isFirstLoading = useIsFirstLoading(isBackupLoading);

    const loadPaginated = React.useCallback(
        (page: number, cancelToken?: CancelTokenSource) => getBackups(item.id, { page }, cancelToken),
        [getBackups, item.id]
    );

    const renderSwitchTooltip = () => {
        if (!item.backup_settings.enabled && !item.plan.is_backup_available) {
            return (<Translate content="backupCard.switchTooltip.planNotAvailable" />);
        }

        return undefined;
    };

    return (
        <>
            <BackupCard
                nextScheduledBackupAt={item.next_scheduled_backup_at}
                backupSettings={item.backup_settings}
                setBackupSettings={setBackupSettings}
                additionalConfirmTextOnEnable={confirmTextOnEnable}
                shouldConfirmOnDisable={true}
                isBackupSettingsUpdating={isBackupSettingsUpdating}
                backupPrice={item.plan.backup_price}
                backupsNumber={item.backup_settings.limit}
                disabled={!item.plan.is_backup_available}
                switchTooltip={renderSwitchTooltip()}
            />
            {shouldShowBackups && (
                <BackupsTableContainer>
                    <BackupsTable
                        isFirstLoading={isFirstLoading}
                        loadPaginated={loadPaginated}
                        isServerProcessing={item.is_processing}
                        columns={columns}
                        shouldHideStatusDetailsButton={shouldHideStatusDetailsButton}
                    />
                </BackupsTableContainer>
            )}
        </>
    );
};
