// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as tagActions from 'admin/tag/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import { ITagResponse } from 'common/api/resources/Tag';

interface ITag {
    list: IPaginateApiResponse<ITagResponse[]>;
    item: ITagResponse;
}

export type TagAction = ActionType<typeof tagActions>;
export type TagState = ITag;

export const initialTagState: ITagResponse = {
    id: 0,
    key: '',
    values: [],
    is_deleting: false,
};

export default combineReducers<TagState, TagAction>({
    list: (state = paginateInitialState, action: TagAction) => {
        switch (action.type) {
        case getType(tagActions.addTagItem):
            return addToPaginated(state, action.payload);
        case getType(tagActions.setTagList):
            return action.payload;
        case getType(tagActions.removeTagItem):
            return deleteFromPaginated(state, action.payload);
        case getType(tagActions.updateTagItem):
            return updateInPaginated(state, action.payload);
        case getType(tagActions.setTagItemIsDeleting):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialTagState, action) => {
        switch (action.type) {
        case getType(tagActions.setTagItem):
            return action.payload;
        case getType(tagActions.unsetTagItem):
            return { ...initialTagState };
        default:
            return state;
        }
    },
});
