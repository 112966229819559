// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import {
    ITagListRequest,
    ITagRequest,
    ITagResponse,
} from 'common/api/resources/Tag/model';

export const tag = {
    list: (params?: ITagListRequest) => api.get<IPaginateApiResponse<ITagResponse[]>>('tags', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<ITagResponse>>(`tags/${id}`),
    remove: (id: number) => api.delete(`tags/${id}`),
    create: (data: ITagRequest) => api.post<IApiResponse<ITagResponse>>('tags', data),
    update: (id: number, data: ITagRequest) => api.put<IApiResponse<ITagResponse>>(`tags/${id}`, data),
};
