// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Action,
    IntentType,
    ProgressStep,
    Text,
    Translate,
} from '@plesk/ui-library';
import List from 'common/components/List/List';
import {
    BackupStatus,
    BackupType,
    BackupCreationMethod,
    IBackupResponse,
    BackupRestoreUnavailableReason,
} from 'common/api/resources/Backup';
import { formatTableDate } from 'common/date';
import { Size } from 'common/components/Size/Size';
import { StyledActions } from 'common/components/Actions/Styles';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import * as backupActions from 'common/modules/backup/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { dataCySelector } from 'common/tests/selectors';
import {
    ACTIONS,
    BACKUPS,
    ELEMENTS,
} from 'common/modules/backup/constants/tests';
import { getActionColumnProps } from 'common/helpers/list';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import ActionStatus from 'common/components/ActionStatus/ActionStatus';
import { ITaskListFilters } from 'common/api/resources/Task';
import {
    AxiosResponse,
    CancelTokenSource,
} from 'axios';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';
import ButtonWithInputConfirmation from 'common/components/ButtonWithInputConfirmation/ButtonWithInputConfirmation';
import CopyText from 'common/containers/CopyText/CopyText';

export enum BackupsTableColumns {
    ID = 'colId',
    STATUS = 'colStatus',
    CREATION_METHOD = 'colCreationMethod',
    CREATED_AT = 'colCreatedAt',
    SIZE = 'colSize',
    DISK = 'colDisk',
    DISK_SIZE = 'colDiskSize',
    COMPUTE_RESOURCE_VM = 'colComputeResourceVm',
    COMPUTE_RESOURCE = 'colComputeResource',
    BACKUP_NODE = 'colBackupNode',
    BACKUP_TYPE = 'colBackupType',
    CREATOR = 'colCreator',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ACTIONS = 'colActions',
}

export type IBackupsTableColumns = {
    [key in BackupsTableColumns]?: {
        width?: string;
    };
};

interface IBackupsTableProps {
    isServerProcessing: boolean;
    columns: IBackupsTableColumns;
    shouldHideStatusDetailsButton?: boolean;
    withSelection?: boolean;
    selection?: string[];
    onSelectionChange?: (indexes: string[]) => void;
    emptyView?: React.ReactElement;
    isFirstLoading: boolean;
    filters?: ITaskListFilters;
    toolbar?: React.ReactElement;
    loadPaginated: (page: number, cancelToken?: CancelTokenSource)
        => Promise<AxiosResponse<IPaginateApiResponse<IBackupResponse[]>>>;
    onDeletedServerRestore?: (backup: IBackupResponse) => void;
}

export type BackupsTableProps =
    IBackupsTableProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const availableColumnDefinitions = {
    [BackupsTableColumns.ID]: {
        key: BackupsTableColumns.ID,
        width: '1%',
        cellProps: {
            dataCy: 'id',
        },
        title: <Translate content="backup.list.id" />,
    },
    [BackupsTableColumns.STATUS]: {
        key: BackupsTableColumns.STATUS,
        title: <Translate content="backup.list.status" />,
    },
    [BackupsTableColumns.CREATION_METHOD]: {
        key: BackupsTableColumns.CREATION_METHOD,
        title: <Translate content="backup.list.creationMethod" />,
    },
    [BackupsTableColumns.CREATED_AT]: {
        key: BackupsTableColumns.CREATED_AT,
        cellProps: {
            className: 'cell-bold',
        },
        title: <Translate content="backup.list.createdAt" />,
    },
    [BackupsTableColumns.SIZE]: {
        key: BackupsTableColumns.SIZE,
        title: <Translate content="backup.list.backupSize" />,
    },
    [BackupsTableColumns.DISK]: {
        key: BackupsTableColumns.DISK,
        title: <Translate content="backup.list.serverDisk" />,
    },
    [BackupsTableColumns.DISK_SIZE]: {
        key: BackupsTableColumns.DISK_SIZE,
        title: <Translate content="backup.list.size" />,
    },
    [BackupsTableColumns.COMPUTE_RESOURCE_VM]: {
        key: BackupsTableColumns.COMPUTE_RESOURCE_VM,
        title: <Translate content="backup.list.computeResourceVm" />,
    },
    [BackupsTableColumns.COMPUTE_RESOURCE]: {
        key: BackupsTableColumns.COMPUTE_RESOURCE,
        title: <Translate content="backup.list.computeResource" />,
    },
    [BackupsTableColumns.BACKUP_NODE]: {
        key: BackupsTableColumns.BACKUP_NODE,
        title: <Translate content="backup.list.backupNode" />,
    },
    [BackupsTableColumns.BACKUP_TYPE]: {
        key: BackupsTableColumns.BACKUP_TYPE,
        title: <Translate content="backup.list.backupType" />,
    },
    [BackupsTableColumns.CREATOR]: {
        key: BackupsTableColumns.CREATOR,
        title: <Translate content="backup.list.creator" />,
    },
    [BackupsTableColumns.ACTIONS]: getActionColumnProps(),
};

const statusTextMap: Record<BackupStatus, React.ReactNode> = {
    [BackupStatus.PENDING]: <Translate content="backup.status.pending"/>,
    [BackupStatus.IN_PROGRESS]: <Translate content="backup.status.inProgress"/>,
    [BackupStatus.FAILED]: <Translate content="backup.status.failed"/>,
    [BackupStatus.CREATED]: <Translate content="backup.status.created"/>,
    [BackupStatus.CREATING]: <Translate content="backup.status.creating"/>,
    [BackupStatus.RESTORING]: <Translate content="backup.status.restoring"/>,
    [BackupStatus.HIDDEN]: <Translate content="backup.status.created"/>,
};

const intentMap: Record<BackupStatus, IntentType> = {
    [BackupStatus.PENDING]: INTENT_TYPE.WARNING,
    [BackupStatus.IN_PROGRESS]: INTENT_TYPE.WARNING,
    [BackupStatus.FAILED]: INTENT_TYPE.DANGER,
    [BackupStatus.CREATED]: INTENT_TYPE.SUCCESS,
    [BackupStatus.CREATING]: INTENT_TYPE.INFO,
    [BackupStatus.RESTORING]: INTENT_TYPE.INFO,
    [BackupStatus.HIDDEN]: INTENT_TYPE.SUCCESS,
};

const creationMethodTextMap: Record<BackupCreationMethod, React.ReactNode> = {
    [BackupCreationMethod.AUTO]: <Translate content="backup.type.auto"/>,
    [BackupCreationMethod.MANUAL]: <Translate content="backup.type.manual"/>,
};

const backupTypeTranslationMap = {
    [BackupType.FULL]: <Translate content="backup.type.full"/>,
    [BackupType.INCREMENTAL]: <Translate content="backup.type.incremental"/>,
};

export const BackupsTable: React.FC<BackupsTableProps> = ({
    columns,
    shouldHideStatusDetailsButton,
    canManageBackups,
    items,
    isBackupsLoading,
    isServerProcessing,
    backupsActions: {
        deleteBackup,
        restoreFromBackup,
    },
    withSelection,
    selection,
    onSelectionChange,
    emptyView,
    isFirstLoading,
    filters,
    toolbar,
    loadPaginated,
    onDeletedServerRestore,
}) => {
    const handleRestore = (id: number) => {
        if (onDeletedServerRestore !== undefined) {
            const removedBackup = items.data.find((item: IBackupResponse) => item.id === id && item.compute_resource_vm?.deleted_at !== null);

            if (removedBackup !== undefined) {
                onDeletedServerRestore(removedBackup);
                return;
            }
        }

        restoreFromBackup(id);
    };

    const handleRemove = (id: number) => {
        deleteBackup(id);
    };

    const columnDataRenderers = {
        [BackupsTableColumns.ID]: (item: IBackupResponse) => (
            <Text intent={item.status === BackupStatus.HIDDEN ? INTENT_TYPE.MUTED : undefined}>
                {item.id}
            </Text>
        ),
        [BackupsTableColumns.STATUS]: (item: IBackupResponse) => (
            <ActionStatus
                statusText={statusTextMap[item.status]}
                isMutedStatusText={item.status === BackupStatus.HIDDEN}
                isInProgress={[BackupStatus.CREATING, BackupStatus.RESTORING, BackupStatus.IN_PROGRESS].includes(item.status)}
                progress={item.backup_progress}
                intent={intentMap[item.status]}
                shouldShowDetails={!shouldHideStatusDetailsButton && item.status === BackupStatus.FAILED}
                data-cy={dataCySelector(item.id, ELEMENTS.STATUS)}
                detailsDialog={{
                    title: (<Translate content="backup.detailsDialog.title" />),
                    text: (
                        <ProgressStep icon="gear" status="error">
                            {item.backup_fail_reason}
                        </ProgressStep>
                    ),
                }}
            />
        ),
        [BackupsTableColumns.CREATION_METHOD]: (item: IBackupResponse) => (
            <Text intent={item.status === BackupStatus.HIDDEN ? INTENT_TYPE.MUTED : undefined}>
                {creationMethodTextMap[item.creation_method]}
            </Text>
        ),
        [BackupsTableColumns.CREATED_AT]: (item: IBackupResponse) => {
            if (!item.created_at) {
                return undefined;
            }

            return (
                <Text intent={item.status === BackupStatus.HIDDEN ? INTENT_TYPE.MUTED : undefined}>
                    {formatTableDate(item.created_at)}
                </Text>
            );
        },
        [BackupsTableColumns.SIZE]: (item: IBackupResponse) => (
            <Text intent={item.status === BackupStatus.HIDDEN ? INTENT_TYPE.MUTED : undefined}>
                <Size size={item.size}/>
            </Text>
        ),
        [BackupsTableColumns.DISK]: (item: IBackupResponse) => (
            <Text intent={item.status === BackupStatus.HIDDEN ? INTENT_TYPE.MUTED : undefined}>
                {item.disk}
            </Text>
        ),
        [BackupsTableColumns.DISK_SIZE]: (item: IBackupResponse) => (
            <Text intent={item.status === BackupStatus.HIDDEN ? INTENT_TYPE.MUTED : undefined}>
                {item.disk}
            </Text>
        ),
        [BackupsTableColumns.COMPUTE_RESOURCE_VM]: (item: IBackupResponse) => {
            const vm = item.compute_resource_vm;

            if (!vm) {
                return undefined;
            }

            if (vm.deleted_at !== null) {
                return (
                    <>
                        <Text bold>{vm.name}</Text>
                        <br />
                        <Translate
                            content="backup.list.serverDeletedAt"
                            params={{ date: formatTableDate(vm.deleted_at) }}
                        />
                    </>
                );
            }

            return (
                <Action
                    component={Link}
                    to={pathTo(`servers/${vm.id}`)}
                    data-cy={dataCySelector(item.id, ELEMENTS.COMPUTE_RESOURCE_VM)}
                >
                    {vm.name}
                </Action>
            );
        },
        [BackupsTableColumns.COMPUTE_RESOURCE]: (item: IBackupResponse) => {
            if (!item.compute_resource_vm || !item.compute_resource_vm.compute_resource) {
                return undefined;
            }

            const cr = item.compute_resource_vm.compute_resource;

            return (
                <Action
                    component={Link}
                    to={pathTo(`compute_resources/${cr.id}`)}
                    data-cy={dataCySelector(item.id, ELEMENTS.COMPUTE_RESOURCE)}
                >
                    {cr.host}
                </Action>
            );
        },
        [BackupsTableColumns.BACKUP_NODE]: (item: IBackupResponse) => {
            if (!item.backup_node) {
                return undefined;
            }

            return (
                <Text intent={item.status === BackupStatus.HIDDEN ? INTENT_TYPE.MUTED : undefined}>
                    {item.backup_node.name}
                </Text>
            );
        },
        [BackupsTableColumns.BACKUP_TYPE]: (item: IBackupResponse) => (
            <Text intent={item.status === BackupStatus.HIDDEN ? INTENT_TYPE.MUTED : undefined}>
                {item.type ? backupTypeTranslationMap[item.type] : ''}
            </Text>
        ),
        [BackupsTableColumns.CREATOR]: (item: IBackupResponse) => {
            if (!item.creator) {
                return undefined;
            }

            return (
                <Text intent={item.status === BackupStatus.HIDDEN ? INTENT_TYPE.MUTED : undefined}>
                    {item.creator.email}
                </Text>
            );
        },
        [BackupsTableColumns.ACTIONS]: (item: IBackupResponse) => {
            const deleteDisabled = isServerProcessing ||
                (item.type === BackupType.INCREMENTAL && item.status !== BackupStatus.FAILED);
            const isTheLastFullBackup = item.compute_resource_vm !== null
                && item.compute_resource_vm.deleted_at !== null
                && item.compute_resource_vm.full_backup_count === 1
                && item.status === BackupStatus.CREATED;

            const restoreTooltipMessage = (): string => {
                if (item.restore.is_available) {
                    return 'backup.restorePopover.tooltip';
                }

                switch (item.restore.unavailable_reason) {
                case BackupRestoreUnavailableReason.DOES_NOT_HAVE_VM:
                    return 'backup.restorePopover.disabledTooltip.doesNotHaveServer';
                case BackupRestoreUnavailableReason.BACKUP_DISK_IS_TOO_BIG:
                    return 'backup.restorePopover.disabledTooltip.diskIsTooBig';
                case BackupRestoreUnavailableReason.IS_GARBAGE_BACKUP:
                    return 'backup.restorePopover.disabledTooltip.isGarbageBackup';
                case BackupRestoreUnavailableReason.BACKUP_IS_NOT_CREATED:
                    return 'backup.restorePopover.disabledTooltip.isNotCreatedBackup';
                case BackupRestoreUnavailableReason.VM_IS_RESCUE_MODE:
                    return 'backup.restorePopover.disabledTooltip.inRescueMode';
                case BackupRestoreUnavailableReason.VM_IS_SUSPENDED:
                    return 'backup.restorePopover.disabledTooltip.serverIsSuspended';
                case BackupRestoreUnavailableReason.BACKUP_IS_DISABLED:
                    return 'backup.restorePopover.disabledTooltip.backupIsNotEnabled';
                case BackupRestoreUnavailableReason.NO_AVAILABLE_BACKUP_NODES:
                    return 'backup.restorePopover.disabledTooltip.unavailableBackupNodes';
                case BackupRestoreUnavailableReason.TASK_IN_PROGRESS:
                    return 'backup.restorePopover.disabledTooltip.serverIsProcessing';
                case BackupRestoreUnavailableReason.CR_INVALID_STATUS:
                    return 'backup.restorePopover.disabledTooltip.serverInvalidStatus';
                default:
                    return 'backup.restorePopover.disabledTooltip.cannotRestore';
                }
            };

            return (
                <StyledActions>
                    <ButtonWithConfirmation
                        icon={ICONS.RELOAD}
                        translations={{
                            text: (
                                <Translate content="backup.restorePopover.confirmationText" />
                            ),
                            button: (
                                <Translate content="backup.restorePopover.button" />
                            ),
                            title: (
                                <Translate content="backup.restorePopover.title" />
                            ),
                            tooltip: (
                                <Translate content={restoreTooltipMessage()} />
                            ),
                        }}
                        handleConfirm={() => handleRestore(item.id)}
                        disabled={!item.restore.is_available}
                        data-cy={dataCySelector(item.id, ACTIONS.RESTORE)}
                        withConfirmation={item.compute_resource_vm?.deleted_at === null}
                    />
                    {canManageBackups && (
                        <>
                            {isTheLastFullBackup ? (
                                <ButtonWithInputConfirmation
                                    disabled={deleteDisabled}
                                    isLoading={item.is_deleting}
                                    confirmation={item.compute_resource_vm!.name}
                                    icon={ICONS.RECYCLE}
                                    translations={{
                                        confirmationButton: (
                                            <Translate content="backup.buttonWithConfirmation.button" />
                                        ),
                                        title: (
                                            <Translate content="backup.buttonWithConfirmation.title" />
                                        ),
                                        text: (
                                            <Translate
                                                content='backup.buttonWithConfirmation.forDeletedServer.text'
                                                params={{ name: <CopyText isInline={true}>
                                                    <Text bold>{item.compute_resource_vm!.name}</Text>
                                                </CopyText> }}
                                            />
                                        ),
                                        tooltip: (
                                            <Translate content="backup.buttonWithConfirmation.tooltip" />
                                        ),
                                        label: (
                                            <Translate content="backup.buttonWithConfirmation.forDeletedServer.label" />
                                        ),
                                    }}
                                    handleConfirm={() => handleRemove(item.id)}
                                    data-cy={dataCySelector(item.id, ACTIONS.DELETE)}
                                />
                            ) : (
                                <ButtonWithConfirmation
                                    disabled={deleteDisabled}
                                    icon={ICONS.RECYCLE}
                                    isLoading={item.is_deleting}
                                    translations={{
                                        text: item.incremental_backups && (
                                            <Translate
                                                content="backup.buttonWithConfirmation.confirmationTextWithIncremental"
                                                params={{ backups: (
                                                    <Text bold>{item.incremental_backups.join(', ')}</Text>
                                                ) }}
                                            />
                                        ),
                                        button: (
                                            <Translate content="backup.buttonWithConfirmation.button" />
                                        ),
                                        title: (
                                            <Translate content="backup.buttonWithConfirmation.title" />
                                        ),
                                        tooltip: (item.type === BackupType.INCREMENTAL && item.status !== BackupStatus.FAILED) ? (
                                            <Translate content="backup.buttonWithConfirmation.cantDeleteIncrementalBackup" />
                                        ) : (
                                            <Translate content="backup.buttonWithConfirmation.tooltip" />
                                        ),
                                    }}
                                    handleConfirm={() => handleRemove(item.id)}
                                    data-cy={dataCySelector(item.id, ACTIONS.DELETE)}
                                />
                            )}
                        </>
                    )}
                </StyledActions>
            );
        },
    };

    const data = items.data.map(item => {
        const res = {
            key: item.id.toString(),
        };

        Object.keys(columns).forEach((column) => {
            res[column] = columnDataRenderers[column](item);
        });

        return res;
    });

    const filtered = filters !== undefined && Object.values(filters).some(filter => !!filter);

    const props = withSelection ? {
        selection,
        onSelectionChange,
    } : {};

    if (emptyView === null && !filtered && data.length === 0) {
        return null;
    }

    return (
        <>
            <List
                {...props}
                toolbar={toolbar}
                columns={Object.entries(columns).map(([column, settings]) => ({
                    ...availableColumnDefinitions[column],
                    ...settings,
                }))}
                data={data}
                isFirstLoading={isFirstLoading}
                meta={items.meta}
                loadItems={loadPaginated}
                isLoading={isBackupsLoading}
                filtered={filtered || isBackupsLoading}
                emptyView={emptyView}
                data-cy={BACKUPS.TABLE}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    items: state.backup.list,
    isBackupsLoading: state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_LIST),
    canManageBackups: hasPermission(state, PERMISSION_LIST.MANAGE_BACKUPS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    backupsActions: bindActionCreators(backupActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackupsTable);
