// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { HTTP_CODES } from 'common/api/constants';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/apiToken/constants/types';
import {
    apiTokens,
    IApiTokenListRequest,
    IApiTokenRequest,
    IApiTokenResponse,
} from 'common/api/resources/ApiToken';
import { IAppState } from 'admin/core/store';
import {
    loadOnScroll,
    paginateList,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';


export const setApiTokensList = createCustomAction(
    types.SET_API_TOKEN_LIST,
    (data: IPaginateApiResponse<IApiTokenResponse[]>) => ({ payload: data })
);
export const appendApiTokenItem = createCustomAction(
    types.APPEND_API_TOKEN_ITEM,
    (data: IApiTokenResponse) => ({ payload: data })
);
export const appendApiTokens = createCustomAction(
    types.APPEND_API_TOKENS,
    (data: IPaginateApiResponse<IApiTokenResponse[]>) => ({ payload: data })
);
export const removeApiTokenItem = createCustomAction(
    types.REMOVE_API_TOKEN_ITEM,
    (id: string) => ({ payload: id })
);
export const setItemIsLoading = createCustomAction(
    types.SET_IS_LOADING,
    (id: string) => ({ payload: id })
);
export const unsetItemIsLoading = createCustomAction(
    types.UNSET_IS_LOADING,
    (id: string) => ({ payload: id })
);

export const getApiTokens = (request?: IApiTokenListRequest) => async(dispatch: Dispatch) => {
    const apiCall = () => apiTokens.list(request);

    return await paginateList({
        dispatch,
        loadingFlag: LOADING_FLAGS.API_TOKEN_LIST,
        action: setApiTokensList,
        apiCall,
    });
};

export const loadApiTokensOnScroll = ()  => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.apiToken.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.API_TOKEN_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendApiTokens,
        loadingFlag: LOADING_FLAGS.API_TOKEN_LIST_INFINITY_SCROLL,
    });
};

export const createApiToken = (data: IApiTokenRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_AUTH_API_TOKEN));

    try {
        const result = await apiTokens.create(data);

        if (result.status === HTTP_CODES.CREATED) {
            dispatch(appendApiTokenItem(result.data.token));

            return result.data.access_token;
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_AUTH_API_TOKEN));
    }
};

export const removeApiToken = (data: IApiTokenResponse) => async (dispatch: Dispatch) => {
    dispatch(setItemIsLoading(data.id));

    try {
        const result = await apiTokens.remove(data.id);

        if (result.status === HTTP_CODES.NO_CONTENT) {
            dispatch(removeApiTokenItem(data.id));
        }

        return result;
    } finally {
        dispatch(unsetItemIsLoading(data.id));
    }
};
