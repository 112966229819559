// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    paginateList,
    remove,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import * as types from 'admin/tag/constants/types';
import {
    ITagListRequest,
    ITagRequest,
    ITagResponse,
} from 'common/api/resources/Tag/model';
import { tag } from 'common/api/resources/Tag/api';

export const setTagList = createCustomAction(
    types.SET_TAG_LIST,
    (data: IPaginateApiResponse<ITagResponse[]>) => ({ payload: data })
);
export const setTagItem = createCustomAction(
    types.SET_TAG_ITEM,
    (data: ITagResponse) => ({ payload: data })
);
export const unsetTagItem = createCustomAction(types.UNSET_TAG_ITEM);
export const addTagItem = createCustomAction(
    types.ADD_TAG_ITEM,
    (data: ITagResponse) => ({ payload: data })
);
export const updateTagItem = createCustomAction(
    types.UPDATE_TAG_ITEM,
    (data: ITagResponse) => ({ payload: data })
);
export const removeTagItem = createCustomAction(
    types.REMOVE_TAG_ITEM,
    (id: number) => ({ payload: id })
);
export const setTagItemIsDeleting = createCustomAction(
    types.SET_TAG_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const getTags = (request?: ITagListRequest) => async (dispatch: Dispatch) => {
    const apiCall = () => tag.list(request);

    return await paginateList({
        dispatch,
        loadingFlag: LOADING_FLAGS.TAG_LIST,
        action: setTagList,
        apiCall,
    });
};

export const createTag = (data: ITagRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.TAG_ITEM_SAVE,
    action: addTagItem,
    apiCall: tag.create,
    translations: {
        success: 'tag.toasts.saved',
    },
});

export const getTag = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: tag.item,
    action: setTagItem,
    loadingFlag: LOADING_FLAGS.TAG_ITEM,
});

export const updateTag = (id: number, data: ITagRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: tag.update,
    action: updateTagItem,
    loadingFlag: LOADING_FLAGS.TAG_ITEM_SAVE,
    translations: {
        success: 'tag.toasts.saved',
    },
});

export const removeTag = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: tag.remove,
    setLoadingAction: setTagItemIsDeleting,
    action: removeTagItem,
    loadingFlag: LOADING_FLAGS.TAG_ITEM_REMOVE,
    translations: {
        success: 'tag.toasts.deleted',
    },
});
