// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import axios from 'axios';
import {
    isProduction,
    isAdminSite,
} from 'common/helpers/core';

export const getUrl = (prefix: string) => isProduction() ? prefix : process.env.REACT_APP_API_URL + prefix;

export const api = axios.create({
    headers: {
        Application: 'UI',
        Source: isAdminSite() ? 'admin-area' : 'client-area',
    },
    baseURL: getUrl('/api/v1/'),
});
