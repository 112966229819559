// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { StyledTable } from 'common/components/styles/StyledTable';
import {
    FormField,
    List,
    Translate,
} from '@plesk/ui-library';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { ICONS } from 'common/constants';
import {
    ISelectOption,
    ISelectRequiredOption,
    Loader,
} from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'client/account/constants/test';
import { IApiTokenResponse } from 'common/api/resources/ApiToken';
import * as apiTokenActions from 'admin/apiToken/actions';
import ApiTokenDialog from 'admin/apiToken/containers/ApiTokenDialog';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { ValueType } from 'react-select';
import { UserFilter } from 'admin/common/components/Filters/UserFilter/UserFilter';
import FilterForm from 'admin/common/components/FilterForm/FilterForm';
import { getActionColumnProps } from 'common/helpers/list';

export type ApiTokensProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    width: '15%',
    key: 'colName',
    title: <Translate content="apiToken.list.name" />,
}, {
    width: '15%',
    key: 'colUser',
    title: <Translate content="apiToken.list.user" />,
}, {
    width: '15%',
    key: 'colCreated',
    title: <Translate content="apiToken.list.created" />,
}, getActionColumnProps(),
];

export const ApiTokens: React.FC<ApiTokensProps> = ({
    isLoadingList,
    list,
    apiTokenActions: { getApiTokens, loadApiTokensOnScroll, removeApiToken },
}) => {
    React.useEffect(() => {
        getApiTokens();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isDialogOpen, setDialogOpen] = React.useState(false);

    const openCreateDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);
    const handleRemove = (apiToken: IApiTokenResponse) => () => removeApiToken(apiToken);

    const handleUserFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        const type = option as ISelectOption;
        type ? getApiTokens({
            filters: {
                user_id: option ? parseFloat((option as ISelectRequiredOption).value) : 0,
            },
        }) : getApiTokens();
    };

    const data = list.data.map(item => ({
        colName: item.name,
        colUser: item.user ? item.user.email : '',
        colCreated: item.created_at,
        colActions: (
            <ButtonWithConfirmation
                isLoading={item.isLoading}
                translations={{
                    text: (
                        <Translate content="apiToken.confirmationRemove.text" />
                    ),
                    button: (
                        <Translate content="apiToken.confirmationRemove.button" />
                    ),
                    tooltip: (
                        <Translate content="apiToken.confirmationRemove.button" />
                    ),
                }}
                handleConfirm={handleRemove(item)}
                data-cy={dataCySelector(item.id, TABLE_ACTIONS.REMOVE)}
                icon="recycle"
            />
        ),
        key: item.id.toString(),
    }));

    return (
        <>
            <PageHeader
                isButtonShown={true}
                title={<Translate content="apiToken.title"/>}
                buttonText="apiToken.addNewToken"
                buttonIcon={ICONS.HEXAGONS}
                onButtonClick={openCreateDialog}
            />
            <FilterForm>
                <FormField label={<Translate content="apiToken.list.user" />}>
                    <UserFilter onChange={handleUserFilterChange} margin_left="0px" />
                </FormField>
            </FilterForm>
            <StyledTable>
                <InfiniteScroll
                    loadMore={loadApiTokensOnScroll}
                    hasMore={!!list.links.next}
                >
                    <Loader isLoading={isLoadingList}>
                        <List
                            emptyView={
                                <EmptyView
                                    title="apiToken.emptyView.title"
                                    icon="net"
                                />
                            }
                            columns={columns}
                            data={data}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
            <ApiTokenDialog
                key={isDialogOpen.toString()}
                isOpened={isDialogOpen}
                onClose={closeDialog}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    list: state.apiToken.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.API_TOKEN_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    apiTokenActions: bindActionCreators(apiTokenActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokens);
